export const country = [
  {
    country: "India",
    user_count: 4,
  },
  {
    country: "Australia",
    user_count: 3,
  },
];
