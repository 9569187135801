import SealCheck from "../assets/SealCheck.svg";
import Student from "../assets/Student.svg";
import UsersThree from "../assets/UsersThree.png";
import HandCoins from "../assets/HandCoins.svg";
import HandShake from "../assets/HandShake.svg";
import TextSeeAll from "../assets/TextSeeAll.png";
import Alarm from "../assets/Alarm.png";
import CaretRight from "../assets/CaretRight.png";
import Funnel from "../assets/Funnel.svg";
import GenderFemale from "../assets/GenderFemale.png";
import Heart from "../assets/Heart.png";
import Megaphonesimple from "../assets/MegaphoneSimple.png";
import Mosque from "../assets/Mosque.png";
import TrendUp from "../assets/TrendUp.png";
import Filter from "../assets/Filter.png";
import Maskgroup from "../assets/Maskgroup.png";
import Tag from "../assets/Tag.png";
import Frame1 from "../assets/Frame1.png";
import range from "../assets/range.png";
import CardText from "../assets/CardText.png";
import Coins2 from "../assets/Coins2.svg";
import Maskgroup2 from "../assets/Maskgroup2.png";
import Frame2 from "../assets/Frame2.png";
import range2 from "../assets/range2.png";
import Maskgroup3 from "../assets/Maskgroup3.png";
import Frame3 from "../assets/Frame3.png";
import range3 from "../assets/range3.png";
import Maskgroup4 from "../assets/Maskgroup4.png";
import Frame4 from "../assets/Frame4.png";
import range4 from "../assets/range4.png";
import sliderimg from "../assets/sliderimg.png";
import HowItWork from "../assets/HowItWork.png";
import StepsTitle from "../assets/StepsTile.png";
import StepsTitle2 from "../assets/StepsTile2.png";
import StepsTitle3 from "../assets/StepsTile3.png";
import Arrow from "../assets/Arrow.png";
import Airlogo from "../assets/Airlogo.png";
import Rectangle from "../assets/Rectangle.png";
import Button3 from "../assets/Button3.png";
import Button4 from "../assets/Button4.png";
import LogoFooter from "../assets/LogoFooter.png";
import Logo from "../assets/Logo.png"
import HeroSection from "../assets/HeroSection.png";
import HeaderBgImg from "../assets/HeaderBgImg.png";
import one from "../assets/one.svg";
import two from "../assets/two.svg";
import three from "../assets/three.svg";
import person from "../assets/person.svg";
import pencicon from "../assets/pencicon.svg";
import Home from "../assets/Home.svg";
import FooterLogo from "../assets/FooterLogo.png";
import HeaderColor from "../assets/HeaderColor.svg";
import RocketLaunch from "../assets/RocketLaunch.svg";
import coins2 from "../assets/Coins2.svg";
import RocketLaunch2 from "../assets/RocketLaunch2.svg";
import ShareNetwork from "../assets/ShareNetwork.svg";
import share_Tabs from "../assets/share_Tabs.svg";
import account from "../assets/account.svg";
import caretDown from "../assets/caretDown.svg";
import NoData from "../assets/NoData.svg";
import ArrowBack from "../assets/ArrowBack .svg";
import UploadSimple from "../assets/UploadSimple.svg";
import UploadFile from "../assets/UploadFile.svg";
import Success from "../assets/Success.svg";
import MapPin from "../assets/MapPin.png";
import MegaphoneSimple from "../assets/MegaphoneSimple.png";
import WarningCircle from "../assets/WarningCircle.svg";
import HandCoins2 from "../assets/HandCoins2.svg";
import MapPin2 from "../assets/MapPin.svg";
import HomePageHero from "../assets/HomePageHero.png";
import HomePageHeroTab from "../assets/HomePageHeroTab.png";
import LeftArrowBottomSlider from "../assets/leftarrowbs.png";
import sliderimg2 from "../assets/sliderimg2.png";
import Knowingfairseed from "../assets/Knowingfairseed.png";
import VissionMission from "../assets/VissionMission.png";
import valuepage from "../assets/valuepage.png";
import ourteam from "../assets/ourteam.png";
import DashedImageUpload from "../assets/Dashed Image Upload.png";
import UploadIcon from "../assets/UploadIcon.png";
import CausesDetails from "../assets/CausesDetails.svg";
import Clock from "../assets/Clock.svg";
import done from "../assets/done.gif";
import ColorFullDownArrow from "../assets/ColorfulDownArrow.png";
import CheckBoxGradient from "../assets/CheckBoxGradient.png";
import AdminPanelDropzone from "../assets/AdminPanelDropzone.png";
import Dashboard from "../assets/Dashboard.svg";
import DownArrow from "../assets/DownArrow.png";
import riseArrow from "../assets/riseArrow.png";
import StaticGraph from "../assets/StaticGraph.png";
import ThreeUser from "../assets/ThreeUser.svg";
import Coins from "../assets/Coins.svg";
import CompleteVector from "../assets/CompleteVector.svg";
import Vector from "../assets/Vector.svg";
import SealCheck2 from "../assets/SealCheck2.svg";
import SealCheck3 from "../assets/SealCheck3.svg";
import HeaderImage from "../assets/HeaderImage.svg";
import HeaderImage2 from "../assets/HeaderImage2.svg";
import Default_Profile_pic from "../assets/Default_Profile_pic.png";
import Bg404 from "../assets/Bg404.jpg";
import FairseedCrowdfundingForEducation from "../assets/FairseedCrowdfundingForEducation.png";
import WebsiteBannerDesktop from "../assets/WebsiteBannerDesktop.jpeg";
import WebsiteBannerMobile from "../assets/WebsiteBannerMobile.jpeg";
import WebsiteBannerTablet from "../assets/WebsiteBannerTablet.jpeg";
import MapDesktop from "../assets/MapDesktop.jpeg"
import HandDesktop from "../assets/HandDesktop.jpeg"
import MapTablet from "../assets/MapTablet.jpeg"
import HandTablet from "../assets/HandTablet.jpeg"
import MapMobile from "../assets/MapMobile.jpeg"
import HandMobile from "../assets/HandMobile.jpeg"



export default {
  SealCheck,
  // SealCheck2,
  SealCheck3,
  Student,
  UsersThree,
  HandCoins,
  HandShake,
  TextSeeAll,
  Alarm,
  CaretRight,
  Funnel,
  GenderFemale,
  Heart,
  Megaphonesimple,
  Mosque,
  TrendUp,
  Filter,
  Maskgroup,
  Tag,
  Frame1,
  range,
  CardText,
  Coins2,
  Maskgroup2,
  Frame2,
  range2,
  Maskgroup3,
  Frame3,
  range3,
  Maskgroup4,
  Frame4,
  range4,
  sliderimg,
  HowItWork,
  StepsTitle,
  StepsTitle2,
  StepsTitle3,
  Arrow,
  Airlogo,
  Rectangle,
  Button3,
  Button4,
  LogoFooter,
  Logo,
  HeroSection,
  HeaderBgImg,
  one,
  two,
  three,
  person,
  pencicon,
  Home,
  FooterLogo,
  HeaderColor,
  RocketLaunch,
  RocketLaunch2,
  coins2,
  ShareNetwork,
  account,
  caretDown,
  NoData,
  ArrowBack,
  UploadSimple,
  UploadFile,
  Success,
  MapPin,
  MegaphoneSimple,
  WarningCircle,
  HandCoins2,
  MapPin2,
  HomePageHero,
  HomePageHeroTab,
  LeftArrowBottomSlider,
  sliderimg2,
  Knowingfairseed,
  VissionMission,
  valuepage,
  ourteam,
  DashedImageUpload,
  UploadIcon,
  CausesDetails,
  ColorFullDownArrow,
  CheckBoxGradient,
  Clock,
  AdminPanelDropzone,
  Dashboard,
  DownArrow,
  riseArrow,
  StaticGraph,
  ThreeUser,
  Coins,
  CompleteVector,
  Vector,
  HeaderImage,
  HeaderImage2,
  Default_Profile_pic,
  Bg404,
  FairseedCrowdfundingForEducation,
  WebsiteBannerDesktop,
  WebsiteBannerTablet,
  WebsiteBannerMobile,
  MapDesktop,
  MapTablet,
  MapMobile,
  HandDesktop,
  HandTablet,
  HandMobile,
};
