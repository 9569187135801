import Clock from '../assets/Clock.svg'
import UsersThree from '../assets/UsersThree.svg'
import FooterIconTw from '../assets/FooterIconTw.svg'
import FooterIconIn from '../assets/FooterIconIn.svg'
import FooterIconPi from '../assets/FooterIconPi.svg'
import FooterIconYo from '../assets/FooterIconYo.svg'
import MapPin from '../assets/MapPin.svg'
import FooterIconFa from '../assets/FooterIconFa.svg'


export default{
 Clock,
 UsersThree,
 FooterIconTw,
 FooterIconIn,
 FooterIconPi,
 FooterIconYo,
 MapPin,
 FooterIconFa
}