import React from 'react'
import Navbar from '../../../components/layout/Navbar'
import Footer from '../../../components/layout/Footer'
import SmallScreensVerifyEmail from './SmallScreensVerifyEmail'


function ForgotPasswordSmScreens() {
    return (
        <div>
            <Navbar />
            <SmallScreensVerifyEmail />
            <Footer />
        </div>
    )
}

export default ForgotPasswordSmScreens
